/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import Layout from '../../components/layout';
import SEO from "../../components/seo";
import { getOgImage } from "./../../utils/functions";
import ModerationCTA from './../../components/quality-moderation-and-safety/cta-section';
import ModerationSafetyHeading from './../../components/quality-moderation-and-safety/heading';
import ModerationSafetyExcerpt from './../../components/quality-moderation-and-safety/excerpt';
import ModerationSafetyFeaturedImage from './../../components/quality-moderation-and-safety/image';
import ModerationSafetyList from './../../components/quality-moderation-and-safety/modertion-safety-list';
import ConversationAdvertForm from '../../components/conversation-based-advertising/form';

/**
 * QualityModerationAndSafety Component.
 *
 * @param {Object} props Component Props
 *
 * @return {*}
 */
const QualityModerationAndSafety = (props) => {

	const {
		pageContext: {
			page: {
				uri,
				seo,
				title,
				excerpt,
				footerCtaLinks: { footerCtaWrapper },
				featuredImage,
				moderationSafetyMeta: { moderationAndSafetyList },
				mobileFeaturedImage: { mobileFeaturedImage },
				contactUsFormMeta: { contactForm, ctaLink },
			}
		}
	} = props;
	const openGraphImage = getOgImage(seo);

	return (
		<Layout title={title} uri={uri} className="page quality-moderation-and-safety-page">
			<SEO
				title={title}
				seoData={seo}
				uri={uri}
				header={{ siteTitle: 'OpenWeb' }}
				openGraphImage={openGraphImage}
			/>
			<ModerationSafetyHeading title={title} />
			<ModerationSafetyExcerpt excerpt={excerpt} ctaLink={ctaLink} tagParent="PUBLISHERS" tagName="Moderation_Contact" />
			<ModerationSafetyFeaturedImage featuredImage={featuredImage ? featuredImage.node : null} mobileFeaturedImage={mobileFeaturedImage} />
			<ModerationSafetyList lists={moderationAndSafetyList} />
			<ModerationCTA links={footerCtaWrapper} />
			<ConversationAdvertForm meta={contactForm} tagParent="PUBLISHERS" tagName="Moderation_Submit" />
		</Layout>
	)
};

QualityModerationAndSafety.propTypes = {
	pageContext: PropTypes.object,
};

QualityModerationAndSafety.defaultProps = {
	pageContext: {}
};

export default QualityModerationAndSafety;
