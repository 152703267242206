/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import FooterCTA from './../../footer-cta';

import './style.scss';

const ModerationCTA = ( { links } ) => {
	return (
		<>
			{ links && <section id="footer-cta-links" className="moderation-cta__links">
				<FooterCTA links={ links } />
			</section> }
		</>
	);
};

export default ModerationCTA;
